.pagina {
    display: block;
    width: 100%;
    height: auto;
    min-height: calc(100vh - 132px);
    padding: 60px 0;
    background-color: #fff;

    h1 {
        text-align: center;
        color: $roxo;
        margin-bottom: 30px;
    }

    a {
        color: #000;
        &:hover {
            color: $laranja;
        }
        &.btn {
            &.btn-laranja {
                &.btn-block {
                    display: block;
                    width: 100%;
                }
                &:hover {
                    color: #fff;
                }
            }
        }
    }

    .texto {
        text-align: center;
        margin: 0 auto 50px;
        max-width: 790px;
        a {
            color: $roxo;
            text-decoration: underline;

            &.btn {
                text-decoration: none;
                &.btn-laranja {
                    color: #fff;
                }
            }

        }
    }

    form {
        max-width: 800px;
        margin-bottom: 100px;
        overflow: hidden;
        legend {
            color: $laranja;
            font-size: 1.125rem;
            font-weight: bold;
            border-color: $laranja;
        }
        h2 {
            color: $laranja;
            font-weight: bold;
        }
        .texto {
            text-align: center;
        }

        .form-group {
            .form-control {
                border-color: #f1f1f1;
                &:-webkit-autofill {
                    -webkit-box-shadow: 0 0 0 30px #E5E5E5 inset;
                }
            }
        }
    }

    aside {
        h2 {
            text-transform: uppercase;
            color: $roxo;
            font-size: 28px;
        }
        nav {
            display: block;
            ul {
                margin: 0;
                padding: 0;
                li {
                    display: block;
                    a {
                        &.btn {
                            display: block;
                            width: 100%;
                            text-transform: uppercase;
                            margin-bottom: 3px;
                            text-align: left;
                        }
                    }
                }
            }
        }
        .btn {
            &.btn-laranja {
                &.btn-block {
                    display: block;
                    width: 100%;
                }
                &:hover {
                    color: #fff;
                }
            }
        }
    }

}