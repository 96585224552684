.sobre {
    display: block;
    width: 100%;
    height: auto;
    padding: 60px 0;

    h1 {
        text-align: center;
        margin-bottom: 60px;
    }


    .banner {
        display: block;
        width: 100%;
        height: auto;
        background-color: $azul;
        background-image: url($urlAssets + '../imgs/sobre/bg-banner.svg');
        background-repeat: no-repeat;
        background-size: cover;
        padding: 30px 0;
        margin-top: 60px;
        @include breakpoint(tablet) {
            background-image: none;
            padding: 50px 30px;
        }
        img {
            float: right;
            @include breakpoint(tablet) {
                float: none;
                margin: auto auto 30px;
            }
        }
        h2 {
            font-size: 1.5625rem;
            line-height: 2rem;
            color: #fff;
            margin-bottom: 30px;
            @include breakpoint(tablet) {
                text-align: center;
            }
        }
    }

}