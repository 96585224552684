.contato {
    display: block;
    width: 100%;
    height: auto;
    min-height: calc(100vh - 132px);
    padding: 60px 0;
    background-color: #fff;

    h1 {
        text-align: center;
        color: $laranja;
        margin-bottom: 30px;
    }

    .texto {
        text-align: center;
        margin: 0 auto 50px;
        max-width: 790px;
        a {
            color: $roxo;
            text-decoration: underline;

            &.btn {
                text-decoration: none;
                &.btn-laranja {
                    color: #fff;
                }
            }

        }
    }

    .formulario {
        @include breakpoint(tablet) {
            margin-bottom: 40px;
        }
        form {
            label {
                
            }
        }
    }

}