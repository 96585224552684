.vitrine {
    display: block;
    width: 100%;
    height: auto;

    .carousel {
        // display: block;
        width: 100%;
        height: auto;
        // max-height: 300px;
        .owl-stage-outer {
            // -webkit-border-radius: 0 30px 0 0;
            // border-radius: 0 30px 0 0;
        }
        .item {
            width: 100%;
            position: relative;
            height: auto;
            picture {
                position: relative;
                z-index: 1;
                display: block;
                width: 100%;
                height: 34.375rem;
                overflow: hidden;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .conteudo {
                position: absolute;
                z-index: 2;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                color: white;
                h1 {
                    display: block;
                    max-width: 720px;
                    font-weight: 900;
                    font-size: 45px;
                    line-height: 61px;
                    color: #FFFFFF;
                    margin-bottom: 15px;
                }
                .texto {
                    display: block;
                    max-width: 700px;
                    font-size: 20px;
                    line-height: 27px;
                    color: #FFFFFF;
                    padding-bottom: 30px;
                }
                a {
                    background-color: #130E58;
                    font-size: 17.034px;
                    line-height: 23px;
                    color: #FFFFFF;
                    transition: all .3s ease;
                    padding: 12px 28px;
                    border-radius: 8px;
                    &:hover {
                        background-color: #5E51A4;
                    }          
                }
            }
        }
    }
    
}