.direitoDosPassageiros {
    display: block;
    width: 100%;
    height: auto;
    padding: 60px 0 0;

    h1 {
        text-align: center;
        margin-bottom: 60px;
    }

    #direitos {
        margin-bottom: 60px;
    }

    .card {
        -webkit-border-radius: 12px !important;
        border-radius: 12px !important;
        outline: none !important;
        background-color: #fff;
        margin-bottom: 15px;
        .card-header {
            border: 0;
            background-color: $roxo;
            border-radius: 12px;
            outline: none !important;
            h2 {
                button {
                    vertical-align: middle;
                    outline: none !important;
                    text-decoration: none;
                    box-shadow: none;
                    margin-left: 30%;
                    img {
                        max-height: 60px;
                        @include breakpoint(tablet) {
                            display: block;
                            margin: 0 auto 10px;
                        }
                    }
                    span {
                        font-size: 1.375rem;
                        line-height: 1.5rem;
                        font-weight: 700;
                        text-transform: uppercase;
                        color: #fff;
                        margin-left: 15px;
                        margin-top: 5px;
                        @include breakpoint(tablet) {
                            display: block;
                            font-size: 1.125rem;
                            line-height: 1.375rem;
                        }
                    }
                    &:hover {
                        text-decoration: none;
                    }
                }
                &:hover {
                    text-decoration: none;
                }
            }
            &:hover {
                text-decoration: none;
                background-color: $roxoHover;
            }
        }
        .card-body {
            padding: 36px;
            h3 {
                font-weight: 600;
                color: $roxo;
                margin-bottom: 40px;
            }
        }
    }
    

}