footer {
    position: relative;
    display: block;
    width: 100%;
    background-color: $laranja;
    padding: 18px 0;
    transition: all .3s ease;

    .redes {
        display: inline-block;
        @include breakpoint(tablet) {
            display: block;
            margin-top: 50px;
        }
        nav {
            ul {
                li {
                    display: inline-block;
                    a {
                        display: flex;
                        width: 30px;
                        height: 30px;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        border: #FFFFFF 1px solid;
                        text-decoration: none;
                        padding: 0;
                        &:hover {
                            transform: scale(1.1);
                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .menu {
        position: relative;
        display: block;
        text-align: right;

        nav {
            transition: all .4s ease-in-out;
            &.menuNav {
                display: inline-block;
                @include breakpoint(tablet) {
                    display: block;
                }
            }
            ul {
                margin: 0;
                text-align: right;
                li {
                    position: relative;
                    list-style-type: none;
                    display: inline-block;
                    padding: 0 8px;
                    @include breakpoint(tablet) {
                        display: block;
                    }
                    a {
                        position: relative;
                        display: block;
                        color: #FFFFFF;
                        font-weight: 500;
                        font-size: .875rem;
                        padding: 6px 0;
                        transition: all .3s ease;
                        @include breakpoint(tablet) {
                            margin-bottom: 8px;
                        }
                        &::before {
                            display: block;
                            position: absolute;
                            content: '';
                            width: 0;
                            height: 3px;
                            background-color: #FFFFFF;
                            left: 50%;
                            transform: translateX(-50%);
                            bottom: -15px;
                            transition: all .3s ease;
                        }
                        &:hover {
                            text-decoration: none;
                            color: #FFFFFF;
                            &::before {
                                width: 100%;
                            }
                        }
                    }
                    &:last-child {
                        a {
                            padding-right: 0;
                        }
                    }
                }
            }
            &.active {
                right: 0px;
            }
        }

        .redes {
            @include breakpoint(tablet) {
                display: none;
            }
            ul {
                li {
                    &:last-child {
                        a {
                            padding: 4px 6px;
                        }
                    }
                }
            }
        }
        
    }
    
}