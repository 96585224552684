.home {
    display: block;
    width: 100%;
    height: auto;

    .principaisProblemas {
        display: block;
        width: 100%;
        height: auto;
        padding: 50px 0;
        h2 {
            margin-bottom: 40px;
            color: $azul;
            text-align: center;
        }
        .problema {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100vh;
            max-width: 180px;
            max-height: 180px;
            border-radius: 50%;
            background-color: #fff;
            margin: auto;
            transition: all .3s ease;
            @include breakpoint(tablet) {
                max-width: 150px;
                max-height: 150px;
                margin-bottom: 15px;
            }

            .conteudo {
                display: flex;
                flex-direction: column;
                margin: auto;
                .icone {
                    display: flex;
                    align-items: center;
                    min-height: 86px; 
                    max-width: 100px;
                    margin: auto auto 10px; 
                    @include breakpoint(tablet) {
                        max-width: 75px;
                        min-height: 65px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                h3 {
                    font-weight: 600;
                    font-size: .875rem;
                    line-height: 1rem;
                    text-align: center;
                    color: $preto;
                    padding: 0 30px;
                    @include breakpoint(tablet) {
                        font-size: 12px;
                    }
                }
            }

            &:hover {
                text-decoration: none;
                cursor: pointer;
                box-shadow: 0 0 5px 0 #7D7D7D;
            }

        }
    }

    .podeAjudar {
        display: block;
        width: 100%;
        background-color: $roxo;
        padding: 50px 0;

        h2 {
            color: $laranja;
            margin-bottom: 40px;
            text-align: center;
        }

        img {
            @include breakpoint(tablet) {
                margin-bottom: 40px;
            }
        }

        ul {
            margin-left: 30px;
            margin-bottom: 50px;
            li {
                position: relative;
                list-style-type: none;
                font-size: 1rem;
                line-height: 1.375rem;
                color: #fff;
                margin-bottom: 25px;
                &::before {
                    position: absolute;
                    content: '';
                    width: 30px;
                    height: 30px;
                    background-image: url($urlAssets + '../imgs/ajudar/marcador.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    left: -35px;
                    top: -10px;
                }
            }
        }

    }

    .protegido {
        display: block;
        width: 100%;
        padding: 60px 0;
        text-align: center;

        h2 {
            color: $azul;
            text-align: center;
            margin-bottom: 50px;
        }

        .selo {
            margin: auto auto 40px;
        }

        .texto {
            font-size: .875rem;
            line-height: 1.125rem;
            color: $preto;
            text-align: center;
            max-width: 590px;
            margin: auto auto 30px;
        }

    }

}