header {
    position: fixed;
    top: 0;
    z-index: 10;
    display: block;
    width: 100%;
    background-color: #fff;
    padding: 18px 0;
    transition: all .3s ease;
    -webkit-box-shadow: 0 0 7px 0 rgba(0,0,0,.7);
    box-shadow: 0 0 7px 0 rgba(0,0,0,.7);
    
    .menu {
        position: relative;
        display: flex;
        text-align: right;
        align-items: center;
        
        .botaoResponsivo {
            width: 50px;
            height: 40px;
            position: absolute;
            right: 5px;
            display: none;
            margin-top: 0;
            top: -5px;
            z-index: 9999;
            @include breakpoint(768px, max) {
                display: inline-block;
                // top: 0;
            }
            .linhasmr {
                float: right;
                margin-top: 8px;
                .b1 {
                    top: 11px;
                }
                .b2 {
                    top: 19px;
                }
                .b3 {
                    top: 27px;
                }
                >span {
                    background-color: #000;
                    height: 2px;
                    right: 0;
                    position: absolute;
                    top: 0;
                    transition: all 0.3s ease 0s;
                    width: 26px;
                    border-radius: 0px;
                }
            }
            &.active {
                .linhasmr {
                    >span {
                        background-color: #FFF;
                    }
                    .b1 {
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                        top: 19px;
                        right: 0;
                    }
                    .b2 {
                        width: 0px;
                    }
                    .b3 {
                        transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                        top: 19px;
                        right: 0;
                    }
                }
            }
        }
        nav {
            transition: all .4s ease-in-out;
            &.menuNav {
                display: inline-block;
            }
            @include breakpoint(768px, max) {
                width: 100vw;
                display: block;
                position: absolute;
                z-index: 999;
                background-color: $laranja;
                right: -120vw;
                position: fixed;
                top: 0;
                height: auto;
                min-height: 100vh;
                overflow: auto;
                padding-top: 150px;
            }
            ul {
                margin: 0;
                text-align: right;
                @include breakpoint(768px, max) {
                    padding: 0 30px;
                    text-align: left;
                }
                li {
                    position: relative;
                    list-style-type: none;
                    display: inline-block;
                    padding: 0 8px;
                    @include breakpoint(768px, max) {
                        display: block;
                    }
                    a {
                        position: relative;
                        display: block;
                        color: $preto;
                        font-weight: 500;
                        font-size: .875rem;
                        padding: 0;
                        transition: all .3s ease;
                        text-decoration: none;
                        @include breakpoint(768px, max) {
                            font-size: 1.2rem;
                            position: relative;
                            padding: 0;
                            color: #fff;
                            padding-bottom: 8px;
                            border-bottom: #FFF 1px solid;
                            margin-bottom: 15px;
                        }
                        &::before {
                            display: block;
                            position: absolute;
                            content: '';
                            width: 0;
                            height: 3px;
                            background-color: $laranja;
                            left: 50%;
                            transform: translateX(-50%);
                            bottom: -15px;
                            transition: all .3s ease;
                            @include breakpoint(768px, max) {
                                display: none;
                            }
                        }
                        &:hover {
                            text-decoration: none;
                            color: $azul;
                            &::before {
                                width: 100%;
                            }
                        }
                    }
                    &:last-child {
                        a {
                            padding-right: 0;
                        }
                    }
                }
            }
            &.active {
                right: 0px;
            }
            .mobile {
                .infos {
                    display: block;
                    padding: 40px 40px 0;
                    text-align: left;
                    .dado {
                        color: #444444;
                        a {
                            line-height: normal;
                            color: #444444;
                            font-size: 1.75rem;
                            margin-bottom: 10px;
                        }
                    }
                }
                .redes {
                    display: block;
                    nav {
                        position: relative;
                        right: auto;
                        top: auto;
                        background-color: transparent;
                        min-height: 0;
                        padding-top: 40px;
                        ul {
                            li {
                                display: inline-block;
                                padding-right: 0;
                                a {
                                    margin-left: 0;
                                    border: 0;
                                    font-size: 40px;
                                    svg {
                                        path {
                                            fill: #444444;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .redes {
            display: inline-block;
            nav {
                ul {
                    li {
                        display: inline-block;
                        a {
                            border-radius: 50%;
                            border: $laranja 1px solid;
                            width: 30px;
                            height: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            &:hover {
                                transform: scale(1.1);
                                &::before {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.scroll {
        
    }
    
}