.verifiqueSeuDireito {
    display: block;
    width: 100%;
    height: auto;
    padding: 60px 0 0;

    h1 {
        text-align: center;
        margin-bottom: 60px;
    }

    ul {
        display: table;
        margin: auto;
        padding: 0;
        width: 100%;
        max-width: 400px;
        li {
            list-style-type: none;
        }
    }
    .perguntas {
        position: relative;
        display: none;
        width: 100%;
        height: auto;
        min-height: calc(100vh - 314px);
        &.active {
            display: block;
        }
        .pergunta {
            // position: absolute;
            position: relative;
            opacity: 0;
            z-index: 1;
            // top: 0;
            // left: 50%;
            // transform: translateX(-50%);
            display: block;
            overflow: hidden;
            margin: auto;
            width: 100%;
            max-width: 600px;
            transition: all .3s ease;
            height: 0;
    
            h2 {
                text-align: center;
                font-weight: bold;
                margin-bottom: 20px;
            }

            h3 {
                text-align: center;
                margin-bottom: 30px;
            }
            
            a {
                display: block;
                width: 100%;
                height: auto;
                text-decoration: none;
                background-color: #fff;
                border-radius: 8px;
                text-align: left;
                padding: 20px;
                font-size: 16px;
                margin-bottom: 6px;
                transition: all .3s ease;
                &:hover {
                    background-color: $laranja;
                    color: #fff;
                }
                &.active {
                    background-color: $azul;
                    color: #fff;
                }
            }

            &.active {
                opacity: 1;
                z-index: 8;
                overflow: auto;
                height: auto;
            }

            &.formLoginPerguntas {
                max-width: 800px;
                margin-bottom: 100px;
                overflow: hidden;
                legend {
                    color: $laranja;
                    font-size: 1.125rem;
                    font-weight: bold;
                    border-color: $laranja;
                }
                h2 {
                    color: $laranja;
                    font-weight: bold;
                }
                .texto {
                    text-align: center;
                }
            }

        }

        .formDuvida {
            margin-bottom: 30px;
        }
    
    }

    #formTopicos {
        margin-bottom: 100px;

        p {
            font-size: 20px;
            line-height: normal;
            font-weight: bold;
            margin-bottom: 30px;
        }

        .form-check {
            label {
                color: #000;
            }
        }

        .fieldMargem {
            margin: 40px 0;
        }

    }


}