.comoFunciona {
    display: block;
    width: 100%;
    height: auto;
    padding: 60px 0 0;

    h1 {
        text-align: center;
        margin-bottom: 60px;
    }

    .imgPessoa {
        @include breakpoint(tablet) {
            margin-bottom: 40px;
        }
    }

    .passoapasso {
        background-color: #fff;
        padding: 60px 0;
        h2 {
            text-align: center;
            color: $azul;
            margin-bottom: 50px;
        }

        .passo {
            margin-bottom: 40px;
            img {
                @include breakpoint(tablet) {
                    margin-bottom: 30px;
                }
            }
            h3 {
                color: $roxo;
                text-transform: uppercase;
                font-weight: 700;
                margin-bottom: 10px;
                font-size: 1.5625rem;
                line-height: 2.125rem;
            }
            .info {
                font-size: 1rem;
                line-height: 1.5rem;
                color: $preto;
            }
        }
    }

    .banner {
        display: block;
        width: 100%;
        height: auto;
        background-color: $laranja;
        background-image: url($urlAssets + '../imgs/como-funciona/bg-banner.svg');
        background-repeat: no-repeat;
        background-size: cover;
        padding: 30px 0;
        margin-top: 60px;
        @include breakpoint(tablet) {
            background-image: none;
            padding: 50px 30px;
        }
        img {
            // float: right;
            @include breakpoint(tablet) {
                margin-bottom: 40px;
            }
        }
        h2 {
            font-size: 1.5625rem;
            line-height: 2rem;
            color: #fff;
            margin-bottom: 30px;
            text-align: left;
            @include breakpoint(tablet) {
                text-align: center;
            }
        }
    }
    

}