.contrato {
    display: block;
    width: 100%;
    height: auto;
    font-family: Arial, Helvetica, sans-serif;

    .logoSalvoo {
        margin-bottom: 20px;
    }

    h1 {
        color: #000;
        text-transform: uppercase;
        text-align: center;
        font-size: 20px;
        margin-bottom: 20px;
    }

    .container {
        .row {
            .col-3 {
                background-color: #EAF2FA;
                padding: 10px;
                text-align: right;
                font-weight: bold;
            }
            .col-9 {
                padding: 10px;
            }
        }

        .infoData {
            display: block;
            text-align: right;
            margin: 30px 0;
            font-weight: bold;
        }

        .infoAssinatura {
            display: block;
            width: 100%;
            text-align: center;
            margin-bottom: 30px;
            .nome {
                margin-bottom: 3px;
            }
            .infoAss {
                font-weight: bold;
            }
            .info {
                font-style: italic;
            }
        }
    }

}