/*
  SASS Variables for project
 */
$version: "v1.0.0";
$author: "Alexander Moraes";

// COLORS DEFAULT
$valid: #2ecc71;
$error: #FF0000;
$bgError: #f7d8d8;

$cinza: #7D7D7D;

$verde: #245E62;
$verdeFraco: #00636B;
$amarelo: #FDC82F;

$preto: #333333;
$laranja: #F85330;
$roxo: #5E51A4;
$azul: #130E58;


$laranjaHover: #FE2F12;
$roxoHover: #50448D;
$azulHover: #1D1677;



//Tamanho Container
$containerWidth: 1170px;

//Font Padrão do Site
// font-family: 'Manrope', sans-serif;
$fontDefault: 'Manrope', sans-serif;;
$fontDin: 'Manrope', sans-serif;;

$corBarraResponsivo: #FFFFFF;

$theme-colors-rgb: (
  "primary": (0, 123, 255),
  "secondary": (108, 117, 125),
  "success": (40, 167, 69),
  "danger": (220, 53, 69),
  "warning": (255, 193, 7),
  "info": (23, 162, 184),
  "light": (248, 249, 250),
  "dark": (52, 58, 64)
);

// PATHS
$fontsSRC: "../fonts/";
$img: $urlAssets + "../imgs/";

// BREAKPOINTS
$breakpoints: (max: (xs: 320px, mob: 768px, tablet: 1024px), min: (sm: 768px, md: 992px, lg: 1200px));