.ajuda {
    display: block;
    width: 100%;
    height: auto;
    padding: 60px 0;
    background-color: #fff;

    h1 {
        text-align: center;
        margin-bottom: 60px;
    }

    .card {
        border-radius: 0 !important;
        outline: none !important;
        border: 0;
        border-bottom: #E5E5E5 2px solid !important;
        .card-header {
            border: 0;
            // background-color: $roxo;
            border-radius: 0;
            outline: none !important;
            background-color: #fff;
            padding: 0;
            margin-bottom: 30px;
            margin-top: 30px;
            h2 {
                button {
                    position: relative;
                    text-align: left;
                    vertical-align: middle;
                    outline: none !important;
                    padding: 0;
                    border-radius: 0;
                    box-shadow: none;
                    img {
                        position: absolute;
                        left: 10px;
                        transform: rotate(90deg);
                        transition: all .3s ease;
                        max-width: 15px;
                    }
                    span {
                        display: inline-block;
                        font-size: 18px;
                        line-height: 22px;
                        font-weight: 700;
                        // text-transform: uppercase;
                        color: $preto;
                        margin-left: 50px;
                        margin-top: 5px;
                        @include breakpoint(tablet) {
                            font-size: 1.125rem;
                            line-height: 1.375rem;
                        }
                    }
                    &.collapsed {
                        img {
                            transform: none;
                        }
                    }
                    &:hover {
                        text-decoration: none;
                        background-color: #fff;
                        -webkit-appearance: none;
                    }
                    &:focus {
                        outline: none !important;
                        -webkit-appearance: none;
                    }
                }
                &:hover {
                    text-decoration: none;
                    background-color: #fff;
                }
            }
            &:hover {
                text-decoration: none;
                background-color: $roxoHover;
            }
        }
        .card-body {
            padding: 36px;
            border-top: #f1f1f1 1px solid;
            @include breakpoint(tablet) {
                padding-top: 0;
            }
            h3 {
                font-weight: 600;
                color: $roxo;
                margin-bottom: 40px;
            }
        }
    }

}