.btn {
    font-weight: 600;
    padding: 10px 30px;
    border-radius: 10px;
    font-size: 1rem;
    line-height: normal;
    @include breakpoint(768px, max) {
        font-size: 1.1rem;
        display: block;
    }
    &.btn-default {
        background-color: #f1f1f1;
        color: #000;
    }
    &.btn-white {
        background-color: #FFFFFF;
        color: #000000;
        &:hover {
            background-color: #000000;
            color: #fff;
        }
    }
    &.btn-azul {
        background-color: $azul;
        color: #fff;
        &:hover {
            background-color: $azulHover;
        }
    }
    &.btn-laranja {
        background-color: $laranja;
        color: #fff;
        &:hover {
            background-color: $laranjaHover;
        }
    }
    &.btn-roxo {
        background-color: $roxo;
        color: #fff;
        &:hover {
            background-color: $roxoHover;
        }
    }
    &.btn-selection {
        display: block;
        width: 100%;
        height: auto;
        background-color: #fff;
        border-radius: 8px;
        text-align: left;
        padding: 20px;
        font-size: 16px;
        margin-bottom: 6px;
        transition: all .3s ease;
        color: #fff;
        &:hover {
            background-color: $laranja;
            color: #fff;
        }
        &.active {
            background-color: $azul;
            color: #fff;
        }
    }
    &.isDisabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

a {
    &.btn {
        &.btn-laranja {
            text-decoration: none;
            color: #fff;
        }
    }
}