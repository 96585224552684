.assinaturaContratos {
    display: block;
    width: 100%;
    height: auto;

    
}

.modalAssinatura {
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(#FFF, .7);
    display: none;
    .containerModal {
        position: relative;
        width: 100%;
        max-width: 100%;
        height: auto;
        max-height: 100vh;
        background-color: #fff;
        box-shadow: 0 0 5px 0 #D9D9D9;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        h2 {
            margin-bottom: 40px;
        }
        #signature-div {
            height: 90vh;
            // border: #FF0000 1px solid;
        }
    }
    .closeModal {
        position: absolute;
        width: 30px;
        height: 30px;
        right: 20px;
        top: 20px;
        background-image: url($img + 'geral/close.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        transition: all .3s ease;
        &:hover {
            transform: scale(1.1);
            cursor: pointer;
        }
    }
}