.envioDocumentos {

    #formDocumentos {
        label {
            margin-bottom: 6px;
            font-weight: bold;
            // text-transform: uppercase;
        }

        .input-group {
            margin-bottom: 30px;
        }

    }

}