.detalheProcesso {

    h2 {
        font-weight: bold;
        text-transform: uppercase;
        strong {
            color: $laranja;
        }
    }

    .lineDetails {
        display: block;
        width: 100%;
        height: auto;
        padding: 30px;
        border: #dcdcdc 1px solid;
        margin-bottom: 40px;
        .row {
            margin-bottom: 10px;
            .row {
                margin-bottom: 0;
            }
        }

        ul {
            padding: 0;
            margin: 0;
            li {
                list-style-type: none;
                display: inline-block;
                a {
                    margin: 6px;
                    i {
                        margin-right: 4px;
                    }
                }
            }
        }

    }
    
    .sectionTitle {
        display: block;
        width: 100%;
        height: auto;
        text-transform: uppercase;
        font-weight: 900;
        background-color: #f1f1f1;
        border: #dcdcdc 1px solid;
        padding: 10px;
        margin-bottom: 0;
        font-size: 18px;
    }

    .infoText {
        // margin-bottom: 30px;
        word-break: break-word;
    }

}