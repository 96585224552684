.form-group {
    margin-bottom: 8px;
    .form-control {
        border: #E5E5E5 1px solid;
        background-color: #E5E5E5;
        border-radius: 10px;
        color: $preto;
        font-size: 1rem;
        line-height: 1.375rem;
        height: auto;
        outline: none;
        padding: 12px 15px;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $preto;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: $preto;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: $preto;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: $preto;
        }
        &:focus {
            outline: none;
        }
        &.errorField {
            border-color: $error !important;
            color: $error !important;
            background-color: $bgError !important;
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: $error !important;
            }
            &::-moz-placeholder { /* Firefox 19+ */
                color: $error !important;
            }
            &:-ms-input-placeholder { /* IE 10+ */
                color: $error !important;
            }
            &:-moz-placeholder { /* Firefox 18- */
                color: $error !important;
            }
        }
    }
    .form-select {
        border: #FFFFFF 1px solid;
        background-color: #FFFFFF;
        border-radius: 10px;
        color: $preto;
        font-size: 1rem;
        line-height: 1.375rem;
        height: auto;
        outline: none;
        padding: 12px 15px;
    }
    .form-control-white {
        display: block;
        width: 100%;
        border: #FFFFFF 1px solid;
        background-color: #FFFFFF;
        border-radius: 10px;
        color: $preto;
        font-size: 1rem;
        line-height: 1.375rem;
        height: auto;
        outline: none;
        padding: 12px 15px;
    }
    .erroEmail {
        font-size: 0.625rem;
        color: $error;
        margin-top: 2px;
        display: none;
    }
}