.texto {
    font-size: 600px;
    font-size: 1rem;
    line-height: 24px;
    color: $preto;

    p {
        margin-bottom: 30px;

        strong {
            span {
                color: $roxo;
            }
        }

        a {
            color: $laranja;
            font-weight: bold;
            text-decoration: underline;
        }

    }

}